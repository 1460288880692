var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{attrs:{"fluid":""}},[_c('DialogWrapper',{attrs:{"fullscreen":""},on:{"click:outside":function($event){return _vm.closeModal('modal')}},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.modal)?_c('AchievementForm',{attrs:{"edit":_vm.edit,"edit-entity":_vm.activeEntity},on:{"close":_vm.closeModal,"save":_vm.handleSave}}):_vm._e()],1),_c('VRow',[_c('VCol',{attrs:{"cols":"6"}},[_c('h1',{staticClass:"mb-3 tt-text-headline-1"},[_vm._v(" Достижения ")])])],1),_c('VRow',[_c('VCol',{attrs:{"cols":"6"}},[_c('TTSelect',{ref:"locationDropDown",staticClass:"tt-select-attach",attrs:{"placeholder":"Выбор локации","value":_vm.currentLocationId,"items":_vm.locations,"item-text":"name","item-value":"id","large":"","label":"Локация","menu-props":{ auto : true },"attach":"","data-test":"select-location"},on:{"change":_vm.handleLocation},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var disabled = ref.disabled;
return [_c('TextTemplate',{staticClass:"v-select__selection v-select__selection--comma",attrs:{"disabled":disabled,"text":item.name,"show-prefix":!item.is_active && !!item.id}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('TextTemplate',{attrs:{"text":item.name,"show-prefix":!item.is_active && !!item.id}})]}}])})],1)],1),(_vm.currentLocationId)?[(_vm.achievements && _vm.achievements.length)?_c('VRow',[_c('VCol',{staticClass:"pt-4"},[_c('TTDataTable',{ref:"sortableTable",attrs:{"items":_vm.achievements,"extra-tall":"","headers":_vm.headers,"hide-default-footer":"","items-per-page":-1,"data-test":"table-achievements"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('div',{attrs:{"data-test":"table-achievements-name"}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.level && item.level.name)?_c('div',{staticClass:"tt-black--text text--lighten-2"},[_vm._v(" "+_vm._s(item.level.name)+" ")]):_vm._e()]),_c('td',{attrs:{"data-test":"table-achievements-description"}},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('td',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"offset-icon"},[(item.icon_url)?_c('VImg',{attrs:{"src":item.icon_url,"max-width":64,"max-height":64,"contain":""}}):_vm._e()],1),_c('div',[(item.icon_inactive_url)?_c('VImg',{attrs:{"src":item.icon_inactive_url,"max-width":64,"max-height":64,"contain":""}}):_vm._e()],1)]),_c('div',{staticClass:"ml-16"},[_c('TTBtn',{staticClass:"table-menu-button",attrs:{"fab":"","small":"","depressed":"","color":"transparent tt-ghost--text","ripple":false,"data-test":"button-edit"},on:{"click":function($event){return _vm.openModal(item)}}},[_c('VIcon',{attrs:{"size":"19"}},[_vm._v(" fal fa-pen ")])],1)],1)])])])]}}],null,false,1021340063)})],1)],1):_vm._e()]:_c('VRow',[_c('VCol',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"mb-3 tt-text-headline-1"},[_vm._v(" Не задана локация ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Выберите локацию для получения данных. ")])])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }