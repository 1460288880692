var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{attrs:{"fluid":""}},[_c('DialogWrapper',{attrs:{"fullscreen":""},on:{"click:outside":function($event){return _vm.closeModal('modal')}},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.modal)?_c('AchievementForm',{attrs:{"edit":_vm.edit,"edit-entity":_vm.activeEntity},on:{"close":_vm.closeModal,"save":_vm.handleSave}}):_vm._e()],1),_c('VRow',[_c('VCol',{attrs:{"cols":"6"}},[_c('h1',{staticClass:"mb-3 tt-text-headline-1",attrs:{"data-test":"achievements-title"}},[_vm._v(" Достижения ")])])],1),_c('VRow',[_c('VCol',[_c('TabsWrapper',{attrs:{"tabs":_vm.tabs,"underline":"tab","data-test":"tabs"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('VTabsItems',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tab){return _c('VTabItem',{key:tab.text,attrs:{"data-test":tab.dataTest,"transition":false,"value":tab.value}},[(tab.value ==='tracks')?[_c('VContainer',{staticClass:"px-0"},[_c('VRow',[_c('VCol',{attrs:{"cols":"6"}},[_c('TTSelect',{ref:"locationDropDown",refInFor:true,staticClass:"tt-select-attach",attrs:{"placeholder":"Выбрать трек","value":_vm.currentLocationId,"items":_vm.tracks,"item-text":"name","item-value":"id","large":"","label":"Трек","menu-props":{ auto : true },"attach":"","data-test-label":"select-track"},on:{"change":_vm.handleLocation},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var disabled = ref.disabled;
return [_c('TextTemplate',{staticClass:"v-select__selection v-select__selection--comma",attrs:{"disabled":disabled,"text":item.name,"show-prefix":!item.is_active && !!item.id}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('TextTemplate',{attrs:{"text":item.name,"show-prefix":!item.is_active && !!item.id}})]}}],null,true)})],1)],1),(_vm.currentLocationId)?[(_vm.achievements && _vm.achievements.length)?_c('VRow',[_c('VCol',{staticClass:"pt-4"},[_c('TTDataTable',{ref:"sortableTable",refInFor:true,attrs:{"items":_vm.achievements,"extra-tall":"","headers":_vm.headers,"hide-default-footer":"","items-per-page":-1,"data-test":"table-achievements"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('div',{attrs:{"data-test":"table-achievements-name"}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.level && item.level.name)?_c('div',{staticClass:"tt-black--text text--lighten-2"},[_vm._v(" "+_vm._s(item.level.name)+" ")]):_vm._e()]),_c('td',{attrs:{"data-test":"table-achievements-description"}},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('td',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"offset-icon"},[(item.icon_url)?_c('VImg',{attrs:{"src":item.icon_url,"max-width":64,"max-height":64,"contain":""}}):_vm._e()],1),_c('div',[(item.icon_inactive_url)?_c('VImg',{attrs:{"src":item.icon_inactive_url,"max-width":64,"max-height":64,"contain":""}}):_vm._e()],1)]),_c('div',{staticClass:"ml-16"},[_c('TTBtn',{staticClass:"table-menu-button",attrs:{"fab":"","small":"","depressed":"","color":"transparent tt-ghost--text","ripple":false,"data-test":"button-edit"},on:{"click":function($event){return _vm.openModal(item)}}},[_c('VIcon',{attrs:{"size":"19"}},[_vm._v(" fal fa-pen ")])],1)],1)])])])]}}],null,true)})],1)],1):_vm._e()]:_c('VRow',[_c('VCol',{attrs:{"cols":"12","data-test":"empty-section"}},[_c('h1',{staticClass:"mb-3 tt-text-headline-1"},[_vm._v(" Трек не выбран ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Выберите трек для получения данных. ")])])],1)],2)]:_vm._e(),(tab.value==='general')?[_c('VContainer',{staticClass:"px-0"},[(_vm.currentLocationId)?[(_vm.achievements && _vm.achievements.length)?_c('VRow',[_c('VCol',{staticClass:"pt-4"},[_c('TTDataTable',{ref:"sortableTable",refInFor:true,attrs:{"items":_vm.achievements,"extra-tall":"","headers":_vm.headers,"hide-default-footer":"","items-per-page":-1,"data-test":"table-achievements"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('div',{attrs:{"data-test":"table-achievements-name"}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.level && item.level.name)?_c('div',{staticClass:"tt-black--text text--lighten-2"},[_vm._v(" "+_vm._s(item.level.name)+" ")]):_vm._e()]),_c('td',{attrs:{"data-test":"table-achievements-description"}},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('td',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"offset-icon"},[(item.icon_url)?_c('VImg',{attrs:{"src":item.icon_url,"max-width":64,"max-height":64,"contain":""}}):_vm._e()],1),_c('div',[(item.icon_inactive_url)?_c('VImg',{attrs:{"src":item.icon_inactive_url,"max-width":64,"max-height":64,"contain":""}}):_vm._e()],1)]),_c('div',{staticClass:"ml-16"},[_c('TTBtn',{staticClass:"table-menu-button",attrs:{"fab":"","small":"","depressed":"","color":"transparent tt-ghost--text","ripple":false,"data-test":"button-edit"},on:{"click":function($event){return _vm.openModal(item)}}},[_c('VIcon',{attrs:{"size":"19"}},[_vm._v(" fal fa-pen ")])],1)],1)])])])]}}],null,true)})],1)],1):_vm._e()]:_c('VRow',[_c('VCol',{attrs:{"cols":"12","data-test":"empty-section"}},[_c('h1',{staticClass:"mb-3 tt-text-headline-1"},[_vm._v(" Трек не выбран ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Выберите трек для получения данных. ")])])],1)],2)]:_vm._e()],2)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }