<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <VRow>
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <VRow>
              <VCol>
                <h1 class="tt-text-headline-1">
                  Редактирование достижения
                </h1>
              </VCol>
            </VRow>
            <VRow v-if="entity.level && entity.level.name">
              <VCol>
                <h3
                  class="tt-text-subtitle"
                  data-test="achievement-form-level"
                >
                  Уровень "{{ entity.level.name }}"
                </h3>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <TTTextField
                  :value="entity.name"
                  large
                  label="Название"
                  placeholder="Введите название"
                  :error="validation('name').status"
                  :error-messages="validation('name').value"
                  data-test="input-name"
                  @input="v => handleChange('name', v)"
                />
              </VCol>
              <VCol cols="12">
                <TTTextField
                  :value="entity.description"
                  large
                  label="Описание"
                  placeholder="Введите описание"
                  :error="validation('description').status"
                  :error-messages="validation('description').value"
                  data-test="input-description"
                  @input="v => handleChange('description', v)"
                />
              </VCol>
              <template v-if="entity.can_has_description_happy_state">
                <VCol cols="12">
                  <TTTextField
                    :value="entity.description_hs_male"
                    large
                    label="Хеппи-стейт (мужчина)"
                    placeholder="Введите текст"
                    :error="validation('description_hs_male').status"
                    :error-messages="validation('description_hs_male').value"
                    data-test="input-description-hs-male"
                    @input="v => handleChange('description_hs_male', v)"
                  />
                </VCol>
                <VCol cols="12">
                  <TTTextField
                    :value="entity.description_hs_female"
                    large
                    label="Хеппи-стейт (женщина)"
                    placeholder="Введите текст"
                    :error="validation('description_hs_female').status"
                    :error-messages="validation('description_hs_female').value"
                    data-test="input-description-hs-female"
                    @input="v => handleChange('description_hs_female', v)"
                  />
                </VCol>
              </template>
            </VRow>
            <VRow v-if="entity.can_has_icon">
              <VCol cols="6">
                <div class="tt-text-body-2 mb-2">
                  Получено
                </div>
                <UploadImage
                  compact
                  :value="entity.icon_url"
                  data-test="achievement-icon-url"
                  :error="validation('icon_url').status"
                  :error-messages="validation('icon_url').value"
                  @input="v => handleChange('icon_url',v)"
                />
              </VCol>
              <VCol cols="6">
                <div class="tt-text-body-2 mb-2">
                  Не получено
                </div>
                <UploadImage
                  compact
                  :value="entity.icon_inactive_url"
                  data-test="achievement-icon-inactive-url"
                  :error="validation('icon_inactive_url').status"
                  :error-messages="validation('icon_inactive_url').value"
                  @input="v => handleChange('icon_inactive_url',v)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol
                align="left"
                class="mt-3"
              >
                <TTBtn
                  large
                  color="tt-secondary"
                  depressed
                  data-test="button-cancel"
                  @click="handleClose('close')"
                >
                  Отменить
                </TTBtn>
                <TTBtn
                  large
                  :disabled="saving"
                  :loading="saving"
                  depressed
                  class="ml-6"
                  data-test="button-save"
                  @click="save"
                >
                  Сохранить
                </TTBtn>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              depressed
              class="mb-2"
              @click="handleClose('close')"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { validation, handleError, watchValidationStatus } from '@/services';

import UploadImage from '@/components/ui/Uploader.vue';

export default {
  name: 'AchievementForm',
  components: { UploadImage },
  props: {
    editEntity: {
      type: Object,
      default: () => {
      },
    },
    // Флаг, показывающий что форма для редактирования
    edit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      validationStatus: [], // массив статусов валидаций
      saving: false,
      entity: {
        // содержимое сущности
        id: null,
        name: '',
        description: '',
        description_hs_male: '',
        description_hs_female: '',
        icon_url: '',
        icon_inactive_url: '',
      },
    };
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  mounted() {
    // Если это форма редактирования - обработать входящие данные
    if (this.edit) {
      this.handleEntity();
    }
  },
  methods: {
    validation,
    handleError,
    save() {
      this.saving = true;
      const iconActiveUrl = this.entity.icon_url;
      const iconInactiveUrl = this.entity.icon_inactive_url;
      const {
        // eslint-disable-next-line camelcase
        id, name, description, description_hs_male, description_hs_female,
      } = this.entity;

      const data = {
        id,
        name,
        description,
        description_hs_male,
        description_hs_female,
        icon_base64: iconActiveUrl,
        icon_inactive_base64: iconInactiveUrl,
      };

      data.updateFields = [
        'name',
        'description',
        'description_hs_male',
        'description_hs_female',
      ];

      if (this.entity.icon_url.substring(0, 5) !== 'https') {
        data.updateFields.push('icon_base64');
      }

      if (this.entity.icon_inactive_url.substring(0, 5) !== 'https') {
        data.updateFields.push('icon_inactive_base64');
      }

      this.$repositories.achievement.update({ data }).then(() => {
        // всякие действия если нужны а потом событие save
        this.handleClose('save');
        // если ошибка обработать ее этим хэндлером
      })
        .catch((e) => this.handleError(e))
        .finally(() => {
          this.saving = false;
        });
    },

    // сериализовывает входящие данные в требуемые для компонента
    handleEntity() {
      // всякая логика для обработки всего этого
      const entity = this.editEntity;
      this.entity = { ...this.entity, ...entity };
    },
    handleClose(e) {
      this.entity = {};
      // обнуляем все что нужно обнулить и эмитим событие 'close'
      this.$emit(e || 'close');
    },
    // вешаем на поля на которых есть валидация,
    handleChange(name, value, target) {
      const key = target || name;
      this.entity[name] = value;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
