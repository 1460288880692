<template>
  <div>
    <template v-if="newAchievementsPage">
      <Achievement2 />
    </template>
    <template v-else>
      <Achievement />
    </template>
  </div>
</template>

<script>
import Achievement2 from '@/views/Achievements/AchievementNew.vue';
import Achievement from '@/views/Achievements/Achievement.vue';

export default {
  name: 'Index',
  components: { Achievement, Achievement2 },
  computed: {
    newAchievementsPage() {
      return this.$di.ff.get('show_new_achievements_page');
    },
  },
};
</script>

<style scoped>

</style>
