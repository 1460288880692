<template>
  <VContainer
    fluid
  >
    <DialogWrapper
      v-model="modal"
      fullscreen
      @click:outside="closeModal('modal')"
    >
      <AchievementForm
        v-if="modal"
        :edit="edit"
        :edit-entity="activeEntity"
        @close="closeModal"
        @save="handleSave"
      />
    </DialogWrapper>
    <VRow>
      <VCol cols="6">
        <h1 class="mb-3 tt-text-headline-1">
          Достижения
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="6">
        <TTSelect
          ref="locationDropDown"
          placeholder="Выбор локации"
          :value="currentLocationId"
          :items="locations"
          item-text="name"
          item-value="id"
          large
          label="Локация"
          :menu-props="{ auto : true }"
          class="tt-select-attach"
          attach
          data-test="select-location"
          @change="handleLocation"
        >
          <template #selection="{item,disabled}">
            <TextTemplate
              class="v-select__selection v-select__selection--comma"
              :disabled="disabled"
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
          <template #item="{item}">
            <TextTemplate
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
        </TTSelect>
      </VCol>
    </VRow>
    <template v-if="currentLocationId">
      <VRow v-if="achievements && achievements.length">
        <VCol class="pt-4">
          <TTDataTable
            ref="sortableTable"
            :items="achievements"
            extra-tall
            :headers="headers"
            hide-default-footer
            :items-per-page="-1"
            data-test="table-achievements"
          >
            <template #item="{item}">
              <tr>
                <td>
                  <div data-test="table-achievements-name">
                    {{ item.name }}
                  </div>
                  <div
                    v-if="item.level && item.level.name"
                    class="tt-black--text text--lighten-2"
                  >
                    {{ item.level.name }}
                  </div>
                </td>
                <td data-test="table-achievements-description">
                  {{ item.description }}
                </td>
                <td>
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center">
                      <div class="offset-icon">
                        <VImg
                          v-if="item.icon_url"
                          :src="item.icon_url"
                          :max-width="64"
                          :max-height="64"
                          contain
                        />
                      </div>
                      <div>
                        <VImg
                          v-if="item.icon_inactive_url"
                          :src="item.icon_inactive_url"
                          :max-width="64"
                          :max-height="64"
                          contain
                        />
                      </div>
                    </div>
                    <div class="ml-16">
                      <TTBtn
                        class="table-menu-button"
                        fab
                        small
                        depressed
                        color="transparent tt-ghost--text"
                        :ripple="false"
                        data-test="button-edit"
                        @click="openModal(item)"
                      >
                        <VIcon size="19">
                          fal fa-pen
                        </VIcon>
                      </TTBtn>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </TTDataTable>
        </VCol>
      </VRow>
    </template>
    <VRow
      v-else
    >
      <VCol cols="12">
        <h1 class="mb-3 tt-text-headline-1">
          Не задана локация
        </h1>
        <p class="mb-0">
          Выберите локацию для получения данных.
        </p>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { pageLocationToken } from '@/services';
import AchievementForm from '@/components/forms/AchievementForm.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import TextTemplate from '@/components/shared/TextTemplate.vue';

export default {
  name: 'Achievement',
  components: { TextTemplate, DialogWrapper, AchievementForm },
  data() {
    return {
      locations: [],
      achievements: [],
      currentLocationId: null,
      headers: [
        {
          text: 'Название',
          align: 'left',
          value: 'name',
          sortable: false,
          width: 210,
        },
        {
          text: 'Описание',
          align: 'left',
          value: 'description',
          sortable: false,
        },
        {
          text: 'Изображение',
          align: 'left',
          value: 'icon_url',
          sortable: false,
          width: 255,
        },
      ],
      modal: false,
      edit: false,
      activeEntity: {},
    };
  },
  created() {
    const loc = pageLocationToken.get();
    if (loc) {
      this.currentLocationId = parseInt(loc, 10);
      this.$repositories.achievement.list({ data: { filter: { location_id: loc } } }).then((r) => {
        const { data } = r.data;
        this.achievements = data;
      }).catch((e) => console.warn(e));
    }
    this.$repositories.location.list({}).then((r) => {
      const { data } = r.data;
      this.locations = data;
    }).catch((e) => console.warn(e));
  },
  mounted() {
    if (!this.currentLocationId) {
      this.$refs.locationDropDown.$children[0].isMenuActive = true;
    }
  },
  methods: {
    handleLocation(id) {
      pageLocationToken.set(id);
      this.currentLocationId = id;
      this.$repositories.achievement.list({ data: { filter: { location_id: id } } }).then((r) => {
        const { data } = r.data;
        this.achievements = data;
      }).catch((e) => console.warn(e));
    },
    handleSave() {
      // закрыть окно
      this.closeModal('modal');
      this.$repositories.achievement.list({ data: { filter: { location_id: this.currentLocationId } } })
        .then((r) => {
          const { data } = r.data;
          this.achievements = data;
        })
        .catch((e) => console.warn(e));
    },
    openModal(item) {
      this.modal = true;
      this.activeEntity = item;
      this.edit = true;
    },
    closeModal(name) {
      this[name] = false;
      this.modal = false;
      this.edit = false;
      this.activeEntity = {};
    },
  },
};
</script>

<style >
  .icon_url {
    z-index: 1;
  }
  .icon_inactive_url {
    position: absolute;
    z-index: 0;
    left: 28px;
    top: 0px;
  }
</style>
